var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-flex",
        { attrs: { xs6: "" } },
        [
          _c("ResizableText", {
            staticClass: "h-full pr-4",
            attrs: { message: _vm.instructions },
          }),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs6: "" } },
        [
          _c("img", {
            staticClass: "qr-code",
            attrs: { src: _vm.qrcode, alt: "QR Code" },
          }),
          _c(
            "RtbButton",
            {
              staticClass: "absolute bottom-0 right-0 mr-1 mb-2",
              attrs: { size: "sm", outline: "", variant: "icon" },
              on: { click: _vm.zoom },
            },
            [_c("SvgIcon", { attrs: { name: "zoom-in", size: "small" } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }