




















import { computed, defineComponent, ref } from "@vue/composition-api"
import { useQRCode } from "@vueuse/integrations/useQRCode"
import { RtbButton } from "@/components/ui"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import useStore from "@/use/useStore"

const DEFAULT_INSTRUCTIONS = "One person per team"
export default defineComponent({
  name: "QRCode",
  components: { ResizableText, RtbButton },
  setup() {
    const text = ref(window.location.href + "?auth=0&anon=1")
    const qrcode = useQRCode(text)
    const { store } = useStore()
    const mission = computed(() => store.getters.getCurrentMission)
    const instructions = computed(() => {
      return mission.value.instructions || DEFAULT_INSTRUCTIONS
    })
    return { qrcode, instructions }
  },
  methods: {
    zoom() {
      this.$bus.$emit("zoomMissionAssetPhoto", {
        visible: true,
        photo: this.qrcode
      })
    }
  }
})
